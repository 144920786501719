<p-table
  [attr.data-testId]="dataTestId"
  [columns]="columns"
  [value]="values"
  [paginator]="paginator"
  [globalFilterFields]="globalFilterFields"
  [filters]="filters"
  [currentPageReportTemplate]="currentPageReportLocalized"
  [showCurrentPageReport]="showCurrentPageReport"
  [alwaysShowPaginator]="alwaysShowPaginator"
  [rows]="rows"
  [rowsPerPageOptions]="rowsPerPageOptions"
  [totalRecords]="totalRecords"
  [lazy]="lazy"
  [showLoader]="false"
  [scrollable]="scrollable"
  [scrollHeight]="scrollHeight"
  [tableStyleClass]="tableStyleClass"
  [reorderableColumns]="reorderableColumns"
  [loading]="isLoading"
  [selection]="selection"
  (sortFunction)="customSortEmitter.emit($event)"
  (onLazyLoad)="lazyLoadEmitter.emit($event)"
  (onFilter)="filterEmitter.emit($event)"
  (onSort)="sortHandler($event)"
  (onRowReorder)="rowReorder.emit($event)"
  (onRowSelect)="rowSelectionChangeHandler($event, true)"
  (onRowUnselect)="rowSelectionChangeHandler($event, false)"
  (selectionChange)="selectAllChangeHandler($event)"
>
  @if (enableBulkActions || headerColumns) {
    <ng-template pTemplate="header">
      <tr>
        @for (col of headerColumns; track col; let i = $index) {
          <th [attr.class]="col.styleClass" [attr.style]="col.style">
            @if (enableBulkActions && !selection.length) {
              @if (col.columnType === TableColumnType.ICON) {
                <db-button-icon
                  [icon]="col.icon!"
                  [disabled]="col.disabled!"
                  (click)="col.onClick ? col.onClick() : null"
                ></db-button-icon>
              } @else {
                {{ col.header }}
              }
            }
            @if (
              enableBulkActions &&
              selection.length &&
              i === bulkActionsColumnIndex
            ) {
              @if (!fromAcceptScreen) {
                <div class="d-flex align-items-center gap-24 ml-8">
                  <db-button-icon
                    icon="pi-pencil"
                    [dynamicSize]="true"
                    (click)="bulkEdit.emit(selection)"
                  ></db-button-icon>
                  <db-button-icon
                    icon="pi-trash"
                    [dynamicSize]="true"
                    (click)="bulkDelete.emit(selection)"
                  ></db-button-icon>
                  <span class="ml-16">{{
                    selection.length | i18nPlural: selectionCountPluralMap
                  }}</span>
                </div>
              } @else {
                <div class="d-flex align-items-center gap-24 ml-8">
                  <span class="ml-16">{{
                    selection.length | i18nPlural: selectionCountPluralMap
                  }}</span>
                  <db-button-link
                    [label]="'Accept'"
                    (click)="bulkEdit.emit(selection)"
                  ></db-button-link>
                  <db-button-link
                    [label]="'Reject'"
                    (click)="bulkDelete.emit(selection)"
                  ></db-button-link>
                </div>
              }
            }
            @if (
              enableBulkActions && col.columnType === TableColumnType.CHECKBOX
            ) {
              <p-tableHeaderCheckbox />
            }
          </th>
        }
      </tr>
    </ng-template>
  }
  @if (!enableBulkActions) {
    <ng-template pTemplate="header" let-columns>
      <ng-container
        *ngTemplateOutlet="
          headerTemplate ? headerTemplate : defaultHeader;
          context: { columns };
          injector: templateInjector
        "
      ></ng-container>
    </ng-template>
  }

  <ng-template
    pTemplate="body"
    let-rowData
    let-index="rowIndex"
    let-columns="columns"
  >
    <ng-container
      *ngTemplateOutlet="
        getBodyTemplate();
        context: { rowData, index };
        injector: templateInjector
      "
    ></ng-container>
  </ng-template>

  <ng-template pTemplate="loadingbody">
    <ng-container
      *ngTemplateOutlet="defaultLoadingTemplate; injector: templateInjector"
    >
    </ng-container>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <ng-container
      *ngTemplateOutlet="
        emptyMessageTemplate
          ? emptyMessageTemplate
          : defaultEmptyMessageTemplate;
        injector: templateInjector
      "
    >
    </ng-container>
  </ng-template>
</p-table>

<ng-template #defaultHeader>
  <tr>
    @for (col of columns; track col) {
      <th>
        {{ col.header }}
      </th>
    }
  </tr>
</ng-template>

<ng-template #defaultBodyTemplate let-rowData="rowData">
  <tr>
    @for (col of columns; track col) {
      <td>
        {{ rowData[col.field] }}
      </td>
    }
  </tr>
</ng-template>

<ng-template #defaultEmptyMessageTemplate>
  <div class="ml-16">
    <p i18n="@@common|no-results-found">No results found</p>
  </div>
</ng-template>

<ng-template #defaultLoadingTemplate>
  @for (i of skeletonLoaderRows; track i) {
    <tr>
      @for (skeleton of columnSkeletonLoaders; track skeleton) {
        <td>
          <db-skeleton-loader
            [skeletonType]="skeleton.type"
          ></db-skeleton-loader>
        </td>
      }
    </tr>
  }
</ng-template>
