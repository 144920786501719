import {
  Component,
  inject,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { AuthModel, FeatureFlagModel } from "auth-module";
import { GlobalModel } from "global-module";
import { RouterModel } from "router-module";
import {
  companyHasIntegrations,
  distinctUntilChangedDiff,
} from "common-module";
import {
  Observable,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  combineLatest,
} from "rxjs";
import { combineLatestForFrame } from "shared";
import { FeatureFlag, IUserInfo } from "types";
import { isUserFirstAndLastNameInputRequired } from "shared-utils";
import { ResolveBundle, createResolveBundle } from "resolve-module";
import { BreakpointObserver } from "@angular/cdk/layout";

@Component({
  selector: "db-navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.scss"],
})
export class NavigationComponent implements OnChanges {
  @Input() navigationMarginTop = 0;
  contentStyles: { minHeight: string } | undefined;
  sideBarStyles: { marginTop: string } | undefined;

  logo = "/assets/icons/deskbird-logo-bird.svg";

  protected isAdminAppEnv$ = this.routerModel.isAdminAppEnv$.pipe(
    distinctUntilChanged(),
  );
  protected hideIcons$ = this.featureFlagModel.featureHideIconsInMenu$.pipe(
    distinctUntilChanged(),
  );
  protected readonly shouldShowSimplifyMobile$ = inject(BreakpointObserver)
    .observe(["(max-width: 620px)"])
    .pipe(map((result) => result.matches));

  publicApiKeysPageAllowed$ = combineLatest([
    this.authModel.corporateInfo$.pipe(filter(Boolean), distinctUntilChanged()),
    this.globalModel.selectors.adminAppCompanyId$.pipe(
      filter(Boolean),
      distinctUntilChanged(),
    ),
    this.featureFlagModel.featureEnablePublicApiKeys$,
  ]).pipe(
    map(
      ([corporateInfo, companyId, isPublicApiEnabled]) =>
        corporateInfo.id !== companyId || isPublicApiEnabled,
    ),
  );

  title$ = this.isAdminAppEnv$.pipe(
    distinctUntilChanged(),
    map((isAdminApp) =>
      isAdminApp
        ? $localize`:@@navigation-module|client-navigation|admin:Admin`
        : "",
    ),
  );

  logoLink$ = this.isAdminAppEnv$.pipe(
    distinctUntilChanged(),
    map((isAdminApp) =>
      isAdminApp
        ? this.routerModel.adminPartPrefix
        : this.routerModel.clientPartPrefix,
    ),
  );

  isExpanded$ = this.isAdminAppEnv$;

  companyId$ = this.isAdminAppEnv$.pipe(
    switchMap((isAdminAppEnv) =>
      isAdminAppEnv
        ? this.globalModel.selectors.adminAppCompanyId$.pipe(
            distinctUntilChanged(),
          )
        : this.globalModel.selectors.clientAppCompanyId$.pipe(
            distinctUntilChanged(),
          ),
    ),
    filter((cId): cId is string => !!cId),
  );
  officeId$ = this.isAdminAppEnv$.pipe(
    switchMap((isAdminAppEnv) =>
      isAdminAppEnv
        ? this.globalModel.selectors.adminAppOfficeId$.pipe(
            distinctUntilChanged(),
          )
        : this.globalModel.selectors.clientAppOfficeId$.pipe(
            distinctUntilChanged(),
          ),
    ),
    filter((cId): cId is string => !!cId),
  );

  userInfo$: Observable<IUserInfo> = this.authModel.authenticatedUser$.pipe(
    distinctUntilChanged(),
    map(
      (user): IUserInfo => ({
        id: user.id,
        userId: user.id,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        avatarColor: user.avatarColor,
        profileImage: user.profileImage,
        status: user.status,
      }),
    ),
    distinctUntilChangedDiff(),
  );

  isLogged$ = this.authModel.isLoggedIn$.pipe(distinctUntilChanged());
  isLoadingOfficesInitially$ = this.isAdminAppEnv$.pipe(
    distinctUntilChanged(),
    switchMap((isAdminAppEnv) =>
      isAdminAppEnv
        ? this.globalModel.adminAppOffices$.pipe(distinctUntilChanged())
        : this.globalModel.clientAppOffices$.pipe(distinctUntilChanged()),
    ),
    map((offices) => offices === null),
    distinctUntilChanged(),
  );

  hasIntegrations$ = this.authModel.corporateInfo$.pipe(
    map(
      (corporateInfo) => corporateInfo && companyHasIntegrations(corporateInfo),
    ),
  );

  isOnSamlPage$ = this.routerModel.selectors.path$.pipe(
    distinctUntilChanged(),
    map((path) => path.startsWith("/saml")),
  );
  hasError$ = this.routerModel.selectors.path$.pipe(
    distinctUntilChanged(),
    map((path) => path.startsWith("/error")),
  );
  isOffline$ = this.routerModel.selectors.path$.pipe(
    distinctUntilChanged(),
    map((path) => path.startsWith("/offline")),
  );
  isMaintenanceMode$ = this.routerModel.selectors.path$.pipe(
    distinctUntilChanged(),
    map((path) => path.startsWith("/maintenance")),
  );
  isOnLoginPage$ = this.routerModel.selectors.path$.pipe(
    distinctUntilChanged(),
    map((path) => path.startsWith("/login")),
  );
  isOnFreeTrialPage$ = this.routerModel.selectors.path$.pipe(
    distinctUntilChanged(),
    map((path) => path.startsWith("/free-trial")),
  );
  hasNoAccess$ = this.routerModel.selectors.path$.pipe(
    distinctUntilChanged(),
    map((path) => path.startsWith("/no-access")),
  );
  isOnPageWithStickyHeader$ = this.routerModel.selectors.path$.pipe(
    distinctUntilChanged(),
    map((path) => path.includes("/floors-and-spaces/area/")),
  );

  hasPlanningAccess$ = this.authModel.hasPlanningAccess$.pipe(
    distinctUntilChanged(),
  );
  hasBookingAccess$ = this.authModel.hasBookingAccess$.pipe(
    distinctUntilChanged(),
  );

  isUserAdmin$ = this.authModel.isUserAdmin$.pipe(distinctUntilChanged());
  isUserManager$ = this.authModel.isUserManager$.pipe(distinctUntilChanged());
  isUserGroupManager$ = this.authModel.isUserGroupManager$.pipe(
    distinctUntilChanged(),
  );
  isUserGuest$ = this.authModel.isUserGuest$.pipe(distinctUntilChanged());
  isUserOfficeAdmin$ = this.authModel.isUserOfficeAdmin$.pipe(
    distinctUntilChanged(),
  );
  isUserRegularUser$ = this.authModel.isUserRegularUser$.pipe(
    distinctUntilChanged(),
  );
  isUserDeskbirdAdmin$ = this.authModel.selectors.isDeskbirdAdmin$.pipe(
    distinctUntilChanged(),
  );

  userHasDedicatedResources$ = this.authModel.authenticatedUser$.pipe(
    distinctUntilChanged(),
    map((u) => !!u.dedicatedResources?.length),
  );

  userProfileImage$ = this.authModel.userProfileImage$.pipe(
    distinctUntilChanged(),
  );

  roles = this.authModel.authenticatedUser$.pipe(map((user) => user.role));

  showNavigation$ = combineLatestForFrame([
    this.authModel.user$,
    this.authModel.isUserEmailVerified$,
    this.hasError$,
    this.isOffline$,
    this.isMaintenanceMode$,
    this.hasNoAccess$,
    this.isOnLoginPage$,
    this.isOnSamlPage$,
    this.isOnFreeTrialPage$,
    this.shouldShowSimplifyMobile$,
  ]).pipe(
    map(
      ([
        user,
        isUserEmailVerified,
        hasError,
        isOffline,
        isMaintenanceMode,
        hasNoAccess,
        isOnLoginPage,
        isOnSamlPage,
        isOnFreeTrialPage,
        shouldShowSimplifyMobile,
      ]) =>
        user &&
        isUserEmailVerified &&
        isUserFirstAndLastNameInputRequired(user) === false &&
        !hasError &&
        !isOffline &&
        !isMaintenanceMode &&
        !hasNoAccess &&
        !isOnLoginPage &&
        !isOnSamlPage &&
        !isOnFreeTrialPage &&
        !shouldShowSimplifyMobile,
    ),
    distinctUntilChanged(),
  );

  showTitle$ = combineLatestForFrame([this.isExpanded$, this.title$]).pipe(
    map(([isExpanded, title]) => isExpanded && title),
    distinctUntilChanged(),
  );

  sideBarClasses$ = this.isExpanded$.pipe(
    distinctUntilChanged(),
    map((isExpanded) => ({ minimized: !isExpanded })),
  );

  deskbirdLogoRouterLink$ = this.logoLink$.pipe(
    distinctUntilChanged(),
    map((logoLink) => ({ "router-link": !!logoLink })),
  );

  featureEnableCateringAndServices$ =
    this.featureFlagModel.featureEnableCateringAndServices$;
  featureEnableKioskMode$ = this.featureFlagModel.featureEnableKioskMode$;
  featureEnableOfficeEquipment$ =
    this.featureFlagModel.featureEnableOfficeEquipment$;
  featureEnableHybridWorkPolicies$ =
    this.featureFlagModel.featureFlagEnableHybridWorkPoliciesPreview$;

  contentClass$ = combineLatestForFrame([
    this.isOnSamlPage$,
    this.isOnLoginPage$,
    this.isLogged$,
    this.isOnFreeTrialPage$,
    this.hasError$,
    this.isOffline$,
    this.isMaintenanceMode$,
    this.hasNoAccess$,
    this.isExpanded$,
  ]).pipe(
    map(
      ([
        isOnSamlPage,
        isOnLoginPage,
        isLogged,
        isOnFreeTrialPage,
        hasError,
        isOffline,
        isMaintenanceMode,
        hasNoAccess,
        isExpanded,
      ]) => ({
        "no-navigation":
          isOnSamlPage ||
          isOnLoginPage ||
          !isLogged ||
          isOnFreeTrialPage ||
          hasError ||
          (isOffline && isMaintenanceMode) ||
          hasNoAccess,
        "side-nav-minimized": !isExpanded,
      }),
    ),
  );

  adminPartPrefix = this.routerModel.adminPartPrefix;
  clientPartPrefix = this.routerModel.clientPartPrefix;

  userRoleCompanyPageRestrictionsForAdmin$ =
    this.authModel.userRoleCompanyPageRestrictionsForAdmin$;
  userRoleCompanyPageRestrictionsForClient$ =
    this.authModel.userRoleCompanyPageRestrictionsForClient$;

  constructor(
    private routerModel: RouterModel,
    private authModel: AuthModel,
    private globalModel: GlobalModel,
    private featureFlagModel: FeatureFlagModel,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes["navigationMarginTop"] &&
      changes["navigationMarginTop"].previousValue !==
        changes["navigationMarginTop"].currentValue
    ) {
      this.contentStyles = {
        minHeight: "calc(100vh - " + this.navigationMarginTop + "px)",
      };
      this.sideBarStyles = { marginTop: this.navigationMarginTop + "px" };
    }
  }

  navigateToProfileHandler(): void {
    this.routerModel.navigationOutlet("profile", ["info"], {
      queryParamsHandling: "preserve",
    });
  }

  navigateToYourSpacesHandler(): void {
    this.routerModel.navigationOutlet("profile", ["spaces"], {
      queryParamsHandling: "preserve",
    });
  }

  navigateToSignOutHandler(): void {
    this.routerModel.navigationOutlet("logout", ["logout"], {
      queryParamsHandling: "preserve",
    });
  }

  showIconsClientBundle: ResolveBundle[] = [
    createResolveBundle()({
      dispatchRequest: () => {
        this.featureFlagModel.actions.dispatch.loadFlag({
          flag: FeatureFlag.HIDE_ICONS_IN_MENU,
        });
      },
      dispatchRequestCancel: () => {},
      requestSuccess$: this.featureFlagModel.actions.listen.loadFlagSuccess$,
      requestFailure$: this.featureFlagModel.actions.listen.loadFlagFailure$,
    }),
  ];

  adminAppFeatureFlagsBundle: ResolveBundle[] = [
    createResolveBundle(this.globalModel.selectors.adminAppCompany$)({
      dispatchRequest: (_) => {
        this.featureFlagModel.actions.dispatch.loadFlagForAdmin({
          flag: FeatureFlag.ENABLE_CATERING_AND_SERVICES,
        });
      },
      dispatchRequestCancel: () => {},
      requestSuccess$:
        this.featureFlagModel.actions.listen.loadFlagForAdminSuccess$.pipe(
          filter(
            (flag) => flag.flag === FeatureFlag.ENABLE_CATERING_AND_SERVICES,
          ),
        ),
      requestFailure$:
        this.featureFlagModel.actions.listen.loadFlagForAdminFailure$,
    }),
    createResolveBundle(this.globalModel.selectors.adminAppCompany$)({
      dispatchRequest: (_) => {
        this.featureFlagModel.actions.dispatch.loadFlagForAdmin({
          flag: FeatureFlag.ENABLE_OFFICE_EQUIPMENT,
        });
      },
      dispatchRequestCancel: () => {},
      requestSuccess$:
        this.featureFlagModel.actions.listen.loadFlagForAdminSuccess$.pipe(
          filter((flag) => flag.flag === FeatureFlag.ENABLE_OFFICE_EQUIPMENT),
        ),
      requestFailure$:
        this.featureFlagModel.actions.listen.loadFlagForAdminFailure$,
    }),
    createResolveBundle(this.globalModel.selectors.adminAppCompany$)({
      dispatchRequest: (_) => {
        this.featureFlagModel.actions.dispatch.loadFlagForAdmin({
          flag: FeatureFlag.HIDE_ICONS_IN_MENU,
        });
      },
      dispatchRequestCancel: () => {},
      requestSuccess$:
        this.featureFlagModel.actions.listen.loadFlagForAdminSuccess$.pipe(
          filter((flag) => flag.flag === FeatureFlag.HIDE_ICONS_IN_MENU),
        ),
      requestFailure$:
        this.featureFlagModel.actions.listen.loadFlagForAdminFailure$,
    }),
    createResolveBundle(this.globalModel.selectors.adminAppCompany$)({
      dispatchRequest: (_) => {
        this.featureFlagModel.actions.dispatch.loadFlagForAdmin({
          flag: FeatureFlag.ENABLE_PUBIC_API_KEYS,
        });
      },
      dispatchRequestCancel: () => {},
      requestSuccess$:
        this.featureFlagModel.actions.listen.loadFlagForAdminSuccess$.pipe(
          filter((flag) => flag.flag === FeatureFlag.ENABLE_PUBIC_API_KEYS),
        ),
      requestFailure$:
        this.featureFlagModel.actions.listen.loadFlagForAdminFailure$,
    }),
    createResolveBundle(this.globalModel.selectors.adminAppCompany$)({
      dispatchRequest: (_) => {
        this.featureFlagModel.actions.dispatch.loadFlagForAdmin({
          flag: FeatureFlag.ENABLE_HYBRID_WORK_POLICIES_PREVIEW,
        });
      },
      dispatchRequestCancel: () => {},
      requestSuccess$:
        this.featureFlagModel.actions.listen.loadFlagForAdminSuccess$.pipe(
          filter(
            (flag) =>
              flag.flag === FeatureFlag.ENABLE_HYBRID_WORK_POLICIES_PREVIEW,
          ),
        ),
      requestFailure$:
        this.featureFlagModel.actions.listen.loadFlagForAdminFailure$,
    }),
    createResolveBundle(this.globalModel.selectors.adminAppCompany$)({
      dispatchRequest: (_) => {
        this.featureFlagModel.actions.dispatch.loadFlagForAdmin({
          flag: FeatureFlag.ENABLE_KIOSK_MODE,
        });
      },
      dispatchRequestCancel: () => {},
      requestSuccess$:
        this.featureFlagModel.actions.listen.loadFlagForAdminSuccess$.pipe(
          filter((flag) => flag.flag === FeatureFlag.ENABLE_KIOSK_MODE),
        ),
      requestFailure$:
        this.featureFlagModel.actions.listen.loadFlagForAdminFailure$,
    }),
  ];
}
