export enum FeatureFlag {
  ENABLE_BOOKING_FOR_GUESTS = "DEV_ENABLE_BOOKING_FOR_GUESTS",
  ENABLE_SCHEDULING_CALENDAR_SYNC = "ENABLE_SCHEDULING_CALENDAR_SYNC",
  ENABLE_BOOKING_LIMIT_50 = "DEV_ENABLE_BOOKING_LIMIT_50",
  ENABLE_OFFICE_ROLES = "DEV_ENABLE_OFFICE_ROLES",
  ENABLE_HYBRID_WORK_POLICIES_PREVIEW = "ENABLE_HYBRID_WORK_POLICIES_PREVIEW",
  ENABLE_ASSISTANT = "DEV_ENABLE_ASSISTANT",
  HIDE_ICONS_IN_MENU = "HIDE_ICONS_IN_MENU",
  ENABLE_CATERING_AND_SERVICES = "ENABLE_CATERING_AND_SERVICES",
  ENABLE_OFFICE_EQUIPMENT = "ENABLE_OFFICE_EQUIPMENT",
  ENABLE_FAIL_ON_RECURRING_MEETING_CONFLICT = "ENABLE_FAIL_ON_RECURRING_MEETING_CONFLICT",
  ENABLE_PUBIC_API_KEYS = "ENABLE_PUBIC_API_KEYS",
  ENABLE_KIOSK_MODE = "ENABLE_KIOSK_MODE",
  ENABLE_AREA_EXTENDED_NAME_AND_DESCRIPTION = "ENABLE_AREA_EXTENDED_NAME_AND_DESCRIPTION",
  ENABLE_BUFFER_TIMES = "DEV_BUFFER_TIMES",
  ENABLE_OFFICE_RESOURCE_BASED_ANALYTICS = "DEV_ENABLE_OFFICE_RESOURCE_BASED_ANALYTICS",
  ENABLE_MY_TEAMS_MANAGEMENT = "ENABLE_MY_TEAMS_MANAGEMENT",
}
